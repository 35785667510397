<template>
  <div>
    <Loading v-show="injectionLoading" />
    <div v-show="!injectionLoading">
      <div v-for="news in newsList" :key="news.id" class="news-item page-2">
        <p>{{ news.id }}. {{ news.title }}</p>
        <p>{{ news.summary }}</p>
        <p>{{ news.date }} | {{ news.author }} | {{ news.source }}</p>
      </div>
      <button @click="loadMore">
        Load More
      </button>
    </div>
  </div>
</template>

<script>
import dataMixin from '@/mixin/dataMixin.js';
import Loading from '@/components/Loading.vue';

export default {
  components: {
    Loading
  },
  mixins: [dataMixin],
  asyncData ({ store, route }) {
    console.log('page2触发asyncData准备执行vuex中的action进行请求');
    return store.dispatch('fetchPage2Data', { page: 1 }).then(data => {
      console.log('收到请求,处理数据');
      if (Array.isArray(data)) {
        return data;
      } else {
        throw new Error('Invalid data format');
      }
    });
  },
  data () {
    return {
      newsList: []
    }
  },
  created () {
    if (typeof window === 'undefined') {
      const { asyncData } = this.$options;
      if (asyncData) {
        console.log('page2进入非客户端版本的created方法');
        this.dataPromise = asyncData({
          store: this.$store,
          route: this.$route
        }).then(data => {
          this.handleIndividuationData(data);
        }).catch(err => {
          console.error('Data fetch error:', err);
        });
      }
    }
  },
  methods: {
    loadMore () {
      // 实现滚动加载或点击加载更多数据的逻辑
      const nextPage = this.newsList.length / 10 + 1;
      this.$store.dispatch('fetchPage2Data', { page: nextPage }).then(data => {
        if (Array.isArray(data)) {
          this.newsList = this.newsList.concat(data);
        }
      }).catch(err => {
        console.error('Failed to load more news:', err);
      });
    },
    handleIndividuationData (data) {
      // console.log(data);
      if (data && typeof data === 'object') {
        if (Array.isArray(data)) {
          this.newsList = data;
        } else {
          console.error('Invalid data format:', data);
        }
      } else {
        console.error('Invalid data format:', data);
      }
    }
  }
};
</script>

